.CoinStats, .App {
  text-align: center;
}

.HomeContent {
  color:  white;
  font-family:  Roboto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.coinAvatar {
  display: "block";
  margin: 20px auto 0 auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.holdingsForm {
  background:  white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.marketCapDiff {
  border-radius: 8px;
  background: linear-gradient(#131A36, #212d5c);

  color: white;
  padding: 10px;
}

.mcText {

}

.mcHeader {
  font-size:  11px;
  padding:  6px;
}

.mcPrice {
  font-size:  18px;
  font-weight:  500;
  padding:  6px;
}

.mcPrice span {
  font-size:  14px;
  color:  #4caf50;
}

.mcPrice img, .mcHeader img, .dexImage {
  vertical-align: text-bottom;
}

.marketCapDiff .credit {
  font-size:  11px;
  font-weight:  bold;
  opacity:  0.5;
  text-align:  right;
}
